import React, { ReactNode } from "react";
import usePageData from "../../hooks/usePageData";
import Box, { BoxProps } from "@onnit-js/ui/components/box/Box";
import Text from "@onnit-js/ui/components/text";
import FDAWarning from "./disclaimers/FDAWarning";
import { CheckMarkTag } from "@onnit-js/ui/components/tag/Tag";

interface Props extends BoxProps {
    supplementFacts?: ReactNode;
    supplementWarning?: boolean;
    fdaWarning?: boolean;
}

export const SupplementWarningBox = () => {
    return (
        <Box>
            <Text as="p" fontSize={0} color="grays.4" fontWeight="heavy" display="inline" mr={1}>
                WARNING:
            </Text>
            <Text as="p" fontSize={0} display="inline" typeStyle="default" color="grays.4" mb={4}>
                Consult a medical doctor before taking this or any other nutritional supplement if you
                are pregnant, nursing, have or suspect a medical condition or are taking any
                medications. Store in a cool, dry place. Do not use if freshness seal is missing or
                broken. <span className="bold">KEEP OUT OF REACH OF CHILDREN.</span>
            </Text>
        </Box>
    );
}


const UsageAndDisclaimers: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ supplementFacts, supplementWarning = false, fdaWarning = true, children, ...rest }) => {
    const { pageData } = usePageData();

    if (!pageData) return null;

    return (
        <Box {...rest} py={[6, 6, 6, 8]}>
            <Box display={["block", "block", "flex"]} justifyContent="space-around" maxWidth="1600px" mx="auto">
                <Box p={5} flex="0 1 640px">
                    {pageData.suggested_use && (
                        <Box mb={4}>
                            <Text
                                role="heading"
                                aria-level={2}
                                as="p"
                                display="block"
                                fontSize={[6, 8, 10, 12]}
                                lineHeight={5}
                                fontWeight="bold"
                                fontStyle="italic"
                                mb={2}
                            >
                                Facts &amp; Use
                            </Text>

                            {Number(pageData.tags?.length) > 0 && (
                                <Box pb={4}>
                                    {pageData.tags.map((tag, i) => (
                                        <CheckMarkTag label={tag} key={i} mr={1}/>
                                    ))}
                                </Box>
                            )}

                            <Text as="p" fontWeight="heavy" display="inline" mr={1}>
                                Suggested Use:
                            </Text>
                            <Text
                                as="p"
                                display="inline"
                                typeStyle="default"
                                dangerouslySetInnerHTML={{ __html: pageData.suggested_use }}
                            />
                        </Box>
                    )}

                    {supplementWarning && (
                        <SupplementWarningBox />
                    )}

                    {children}

                    {fdaWarning && <FDAWarning/>}
                </Box>
                <Box p={5} flex="0 1 400px">
                    {supplementFacts}
                </Box>
            </Box>
        </Box>
    );
};

export default UsageAndDisclaimers;
